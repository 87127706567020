import { http } from "./http";

const destroy = id => {
  return http.delete(`/comments/${id}`);
};

const edit = data => {
  return http.patch(`/comments/${data.id}`, data);
};

const store = (type, id, data) => {
  return http.post(`/comments/${type}/${id}`, data);
};

export default {
  destroy,
  edit,
  store
};
