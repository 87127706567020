<template>
  <div class="login-form">
    <form class="box" @submit.prevent="register()" v-if="valid">
      <div class="field">
        <div class="control">
          <v-text-field
            type="name"
            placeholder="Werner Beinhart"
            class="input"
            required
            v-model="name"
          />
        </div>
      </div>
      <div class="field">
        <div class="control">
          <v-text-field
            type="password"
            placeholder="*******"
            class="input"
            required
            v-model="password"
          />
        </div>
      </div>
      <div class="field" style="overflow: hidden;">
        <material-picker
          :palette="colors"
          v-model="color"
          style="height: 100px;"
        />
      </div>
      <div class="field">
        <button class="button is-success">
          Platz da, ich komme!
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { Swatches } from "vue-color";

import api from "@/api/auth";

export default {
  name: "Login",

  components: {
    "material-picker": Swatches
  },

  data: () => ({
    color: "",
    colors: [
      ["#FF0000"],
      ["#550000"],
      ["#00FF00"],
      ["#FF00FF"],
      ["#0000FF"],
      ["#008888"],
      ["#888833"],
      ["#FF8800"],
      ["#00FFFF"],
      ["#883388"],
      ["#EEEE00"],
      ["#008800"]
    ],
    name: "",
    password: "",
    valid: false
  }),

  mounted() {
    api.token(this.$route.params.token).then(response => {
      const data = response.data;
      this.colors = this.colors.filter(color => !data.includes(color[0]));
      this.valid = true;
    });
  },

  methods: {
    async requestCsrfToken() {
      return api.csrf();
    },
    async register() {
      await this.requestCsrfToken();
      return api.register({
        name: this.name,
        password: this.password,
        color: this.color.hex,
        token: this.$route.params.token
      }).then((response) => {
          this.$set(this.$auth, 'user', response.data.data);
          this.$auth.is = true;
          this.$router.push({ name: 'Hafen' })
      });
    }
  }
};
</script>
