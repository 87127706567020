import { http } from "./http";

const current = () => {
  return http.get(`/trips/current`);
};

const destroy = id => {
  return http.delete(`/trips/${id}`);
};

const edit = data => {
  if (data instanceof FormData) {
    data.append('_method', 'PATCH');
  } else {
    data._method = 'PATCH';
  }
  return http.post(`/trips/${data.get('id')}`, data);
};

const get = year => {
  return http.get(`/trips/${year}`);
};

const show = id => {
  return http.get(`/trips/show/${id}`);
};

export default {
  current,
  destroy,
  edit,
  get,
  show
};
