var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"harbor-wrapper",style:({
    height: ("calc(var(--vh) * 100 - " + (!_vm.$vuetify.breakpoint.mdAndUp ? '0px' : '0px') + ")")
  })},[_c('div',{directives:[{name:"longpress",rawName:"v-longpress",value:(_vm.onLongPress),expression:"onLongPress"}],ref:"harbor",staticClass:"harbor",attrs:{"id":"harbor"},on:{"click":function($event){$event.preventDefault();return _vm.onClick($event)},"dblclick":_vm.onDoubleClick,"drop":function($event){$event.preventDefault();return _vm.onDrop($event)},"dragover":function($event){$event.preventDefault();},"&mousemove":function($event){return _vm.onMouseMove($event)}}},[_c('input',{ref:"file",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/*"},on:{"change":function($event){return _vm.onFileChange($event.target.files)}}}),_c('div',{staticClass:"gallery_bg",style:({
        backgroundImage: 'url(' + require('@/assets/gallery_bg.png') + ')'
      })}),_c('div',{staticStyle:{"position":"absolute","top":"0","left":"0","right":"0","bottom":"0","overflow":"hidden"}},_vm._l((_vm.images),function(img){return _c('div',{key:img.id,staticClass:"gallery_image",style:({
          backgroundImage:
            'url(/storage/pub/' + img.id + '.jpg?t=' + img.updated_at + ')',
          top: ((_vm.containers[img.id][0][1]) + "px"),
          left: ((_vm.containers[img.id][0][0]) + "px"),
          width: ((_vm.containers[img.id][1][0] - _vm.containers[img.id][0][0]) + "px"),
          height: ((_vm.containers[img.id][1][1] - _vm.containers[img.id][0][1]) + "px")
        })})}),0),(_vm.step == _vm.steps['choosePlace'] && _vm.container)?_c('div',{staticClass:"gallery_preview",style:({
        backgroundImage: 'url(' + _vm.image + ')',
        top: ((_vm.container[1][0][1]) + "px"),
        left: ((_vm.container[1][0][0]) + "px"),
        width: ((_vm.container[1][1][0] - _vm.container[1][0][0]) + "px"),
        height: ((_vm.container[1][1][1] - _vm.container[1][0][1]) + "px")
      })}):_vm._e(),_c('div',{staticClass:"gallery_fg",style:({
        backgroundImage: 'url(' + require('@/assets/gallery_bg_cut.png') + ')'
      })}),_c('a',{staticClass:"gallery_file_input",on:{"click":function($event){return _vm.launchFilePicker()}}})])])}
var staticRenderFns = []

export { render, staticRenderFns }