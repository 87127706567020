import { http } from "./http";

const csrf = () => http.get("/sanctum/csrf-cookie");

const login = data => http.post(`/auth/login`, data);

const logout = () => http.post(`/auth/logout`, {});

const register = data => http.post(`/auth/register`, data);

const token = token => http.post(`/auth/token`, { token });

const update = (data) => http.post(`/auth`, data);

export default {
  csrf,
  login,
  logout,
  register,
  token,
  update
};
