import { http } from "./http";

const load = () => http.get("/pub");

const store = data => http.post("/pub", data);

export default {
  load,
  store
};
