import { http } from "./http";

const destroy = id => {
  return http.delete(`/manuals/${id}`);
};

const edit = data => {
  return http.patch(`/manuals/${data.id}`, data);
};

const index = () => {
  return http.get(`/manuals`);
};

const store = (data) => {
  return http.post(`/manuals`, data);
};

export default {
  destroy,
  edit,
  index,
  store
};
