<template>
  <v-dialog @input="$emit('input', $event)" :value="value" max-width="550px">
    <v-card :key="trip.id" v-for="(trip, index) in trips">
      <v-toolbar color="primary" elevation="0">
        <v-btn
          color="error"
          @click="destroy(trip)"
          elevation="0"
          v-if="$auth.user.is_admin || $auth.user.id == trip.user.id"
        >
          Löschen
        </v-btn>
        <v-spacer />
        <v-btn
          @click="$emit('input', false)"
          color="error"
          fab
          small
          elevation="0"
          v-if="index == 0"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="pa-4" style="overflow: hidden;">
        <v-container class="pa-0" fluid>
          <v-row>
            <v-col class="flex-grow-0">
              <user-avatar size="50px" :user="trip.user" />
            </v-col>
            <v-col class="text-h4">
              {{ trip.user.name }}
            </v-col>
          </v-row>
        </v-container>
        <br />
        <h3 class="mb-2">
          <router-link :to="{ name: 'Trip', params: { id: trip.id } }">{{
            trip.name
          }}</router-link>
        </h3>
        {{
          trip.startDate.toLocaleString("de-DE", {
            year: "numeric",
            month: "numeric",
            day: "numeric"
          })
        }}
        -
        {{
          trip.endDate.toLocaleString("de-DE", {
            year: "numeric",
            month: "numeric",
            day: "numeric"
          })
        }}
        <hr style="margin-top: 0.5em;" />
        <span class="text-h5">{{ trip.details }}</span>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import api from "@/api/trips";
import UserAvatar from "@/components/UserAvatar.vue";

export default {
  name: "Trip_Viewer",

  components: {
    UserAvatar
  },

  props: {
    trips: {
      type: Array,
      default: null
    },
    value: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    display: false,
    media: []
  }),

  methods: {
    addMedia() {},
    destroy(trip) {
      api.destroy(trip.id).then(() => {
        this.$emit("destroyed");
        this.$nextTick(() => (this.display = false));
      });
    },
    show() {
      this.display = true;
    }
  }
};
</script>
