import NoSleep from "nosleep.js";

export default {
  install(Vue) {
    Vue.prototype.$wakeLock = {
      enable: () => {
        // https://github.com/richtr/NoSleep.js/issues/116
        if ("wakeLock" in navigator) {
          delete Object.getPrototypeOf(window.navigator).wakeLock;
        }
        if (!this.noSleep) {
          this.noSleep = new NoSleep();
        }
        if (this.locked) return true;
        this.noSleep.enable();
        this.locked = true;
        return true;
      },
      disable: () => {
        if (!this.locked) return true;
        this.noSleep.disable();
        this.locked = false;
        return true;
      }
    };
  }
};
