<template>
  <v-container>
    <v-form ref="form" @submit.prevent="submit()">
      <v-row>
        <v-col>
          <v-text-field label="Name" name="name" v-model="form.name" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-textarea label="Beschreibung" :rows="3" v-model="form.comment" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-date-picker-menu label="Von" v-model="form.date_from" />
        </v-col>
        <v-col>
          <v-date-picker-menu label="bis" v-model="form.date_to" />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn color="success" type="submit">Erstellen</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
  <!-- <div class="field">
      <label class="label">Name *</label>
      <div class="control">
        <input
          class="input"
          :class="{ 'is-danger': form.errors.has('name') }"
          type="text"
          placeholder="Name"
          v-model="form.name"
        />
        <p class="help is-danger" v-if="form.errors.has('name')">
          <span v-for="error in form.errors.get('name')" :key="error">
            {{ error }}<br />
          </span>
        </p>
      </div>
    </div>
    <div class="field">
      <label class="label">Kommentar</label>
      <div class="control">
        <textarea
          class="textarea"
          :class="{ 'is-danger': form.errors.has('comment') }"
          placeholder="Wir suchen noch Mitfahrer / Keine Mitfahrer / 2te Liga wir kommen / Wir brauchen Bier!"
          v-model="form.comment"
        ></textarea>
        <p class="help is-danger" v-if="form.errors.has('comment')">
          <span v-for="error in form.errors.get('comment')" :key="error">
            {{ error }}<br />
          </span>
        </p>
      </div>
    </div>
    <div class="field is-horizontal">
      <div class="field-body">
        <div class="field-label is-normal mt-4">
          <label class="label">Von</label>
        </div>
        <div class="field">
          <div class="control is-expanded">
            <v-date-picker-menu v-model="form.date_from" />
            <p class="help is-danger" v-if="form.errors.has('date_from')">
              <span v-for="error in form.errors.get('date_from')" :key="error">
                {{ error }}<br />
              </span>
            </p>
          </div>
        </div>
        <div class="field-label is-normal mt-4">
          <label class="label">bis</label>
        </div>
        <div class="field">
          <div class="control is-expanded">
            <v-date-picker-menu v-model="form.date_to" />
            <p class="help is-danger" v-if="form.errors.has('date_to')">
              <span v-for="error in form.errors.get('date_to')" :key="error">
                {{ error }}<br />
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="field" v-if="false">
      <label class="label">
        GPX URL, z.B.
        <a
          href="https://routino.grade.de"
          target="_blank"
          rel="noopener noreferrer"
          >Grade</a
        >
      </label>
      <div class="control">
        <input
          class="input"
          :class="{ 'is-danger': form.errors.has('gpx_url') }"
          type="text"
          placeholder="https://routino.grade.de/results.cgi?uuid=e18c7d1ec7ee8a20abb399a4988dad34;type=shortest;format=gpx-track"
          v-model="form.gpx_url"
        />
      </div>
    </div>
    <label class="label" v-if="false">
      ODER Datei, z.B.
      <a
        href="https://www.navionics.com/deu/blog/post/new-gpx-import-export-and-more-for-sharing-in-the-boating-app"
        target="_blank"
        rel="noopener noreferrer"
        >Navionics</a
      >
    </label>
    <div class="file has-name" v-if="false">
      <label class="file-label">
        <input
          class="file-input"
          :class="{ 'is-danger': form.errors.has('gpx_file') }"
          type="file"
          name="gpx_file"
          @change="updateFile($event)"
        />
        <span class="file-cta">
          <span class="file-label">
            Datei wählen
          </span>
        </span>
        <span class="file-name" v-if="form.gpx_file">
          {{ form.gpx_file.name }}
        </span>
      </label>
    </div>
    <div class="field is-grouped" style="margin-top: 20px;">
      <div class="control">
        <button class="button is-primary" type="submit">Absenden</button>
      </div>
    </div>
  </form> -->
</template>

<script>
import Form from "form-backend-validation";
import VDatePickerMenu from "./vuetify/VDatePickerMenu.vue";

export default {
  name: "Tour_Form",

  components: { VDatePickerMenu },

  props: {
    model: {
      type: Object,
      required: true
    }
  },

  data: () => ({
    form: null,
    type: "trips"
  }),

  created() {
    this.form = new Form(
      {
        comment: this.model.comment || "",
        name: this.model.name || "",
        date_from: this.model.date_from || "",
        date_to: this.model.date_to || "",
        gpx_file: "",
        gpx_url: ""
      },
      {
        resetOnSuccess: true
      }
    );
  },

  methods: {
    reset() {
      this.form.reset();
    },
    submit() {
      this.form
        .post(`/api/${this.type}`)
        .then(response => {
          this.$emit("add", response);
          setTimeout(() => this.$emit("close"), 500);
        })
        .catch(error => console.log(error));
    },
    updateFile(e) {
      const files = e.target.files || e.dataTransfer.files;
      console.log(e, files);
      if (!files.length) return;
      this.form.gpx_file = files[0];
    }
  }
};
</script>
