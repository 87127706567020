<template>
  <div class="login-form px-5 pt-5">
    <img :src="require('@/assets/privat.png')" />
    <v-form class="box" @submit.prevent="submit()">
      <v-container fluid>
        <v-row>
          <v-col>
            <v-text-field
              label="Name"
              placeholder="Werner Beinhart"
              required
              v-model="name"
              @keydown.enter="submit()"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="Passwort"
              placeholder="*******"
              required
              type="password"
              v-model="password"
              @keydown.enter="submit()"
            />
          </v-col>
        </v-row>
        <!-- <div class="field">
        <label for="" class="checkbox">
          <input type="checkbox" />
          Bin bestimmt demnächst wieder hier!
        </label>
      </div> -->
        <v-row>
          <v-col>
            <v-btn
              @click="submit()"
              color="success"
              elevation="0"
              :loading="loading"
            >
              Platz da, ich komme!
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import api from "@/api/auth";

export default {
  name: "Login",

  data: () => ({
    loading: false,
    name: "",
    password: ""
  }),

  methods: {
    async requestCsrfToken() {
      return api.csrf();
    },
    async submit() {
      this.loading = true;
      await this.requestCsrfToken();
      return api
        .login({
          name: this.name,
          password: this.password
        })
        .then(response => {
          this.$auth.user = response.data.data;
          this.$auth.is = true;
          this.$toast("Erfolgreich angemeldet!");
          this.$router.push({ name: "Start" });
        })
        .catch(() => this.$toast.error("Fehler beim Anmelden!"))
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="scss">
.login-form {
  width: min(350px, 100vw);
  height: 100vh;
  place-items: center center;
  text-align: center;
  margin: auto;

  img {
    max-width: 100%;
  }
  //   position: absolute;
  //   width: max(300px, 50vw);
  //   max-width: min(300px, 100vw);
  //   top: 50%;
  //   left: 50%;
  //   transform: translate3d(-50%, -50%, 0);
}
</style>
