import { http } from "./http";

const destroy = id => {
  return http.delete(`/tours/${id}`);
};

const get = (year) => {
  return http.get(`/tours/${year}`);
};

export default {
  destroy,
  get
};
