<template>
  <svg
    :width="46.708 / 61.464 * parseFloat(size, 10) + 'em'"
    :height="size"
    version="1.1"
    viewBox="0 0 46.708 61.464"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    style="margin-bottom: -0.3em;"
  >
    <path
    :fill="color"
    d="m46.729 0.033498h-31.347v11.01h10.824v35.699l19.089 14.763v-13.847l-8.7079-6.6468v-30.041h10.109z"
    />
  </svg>
</template>

<script>
export default {
  name: "T",

  props: {
    color: {
      type: String,
      default: "#000000"
    },
    size: {
        type: String,
        default: "1em"
    }
  }
};
</script>
