<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    left
    :nudge-right="40"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ attrs }">
      <v-text-field
        @click="!disabled && (menu = true)"
        autcomplete="off"
        :name="`${_uid}-${new Date().getTime()}`"
        :value="displayDate"
        v-bind="attrs"
        style="min-width: 100px;"
        :style="{ pointerEvents: disabled ? 'none' : 'inherit' }"
      ></v-text-field>
    </template>
    <v-date-picker
      :value="date"
      @input="
        menu = false;
        $emit('input', $event);
      "
    ></v-date-picker>
  </v-menu>
</template>

<script>
export default {
  name: "v-date-picker-menu",

  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: String
    }
  },

  data: () => ({
    date: "",
    displayDate: "",
    menu: false
  }),

  watch: {
    value(val) {
      this.setValue(val);
    }
  },

  mounted() {
    this.setValue(this.value);
  },

  methods: {
    setValue(val) {
      const date = new Date(val);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      this.date = `${year}-${month}-${day}`;
      this.displayDate = `${day}.${month}.${year}`;
    }
  }
};
</script>
