<template>
  <div></div>
</template>

<script>
import JsCalendar from "js-year-calendar";
import "js-year-calendar/locales/js-year-calendar.de";
import "js-year-calendar/dist/js-year-calendar.css";

export default {
  name: "Calendar",

  props: [
    "allowOverlap",
    "alwaysHalfDay",
    "contextMenuItems",
    "customDayRenderer",
    "customDataSourceRenderer",
    "dataSource",
    "disabledDays",
    "disabledWeekDays",
    "displayDisabledDataSource",
    "displayHeader",
    "displayWeekNumber",
    "enableContextMenu",
    "enableRangeSelection",
    "hiddenWeekDays",
    "language",
    "loadingTemplate",
    "maxDate",
    "minDate",
    "roundRangeLimits",
    "renderStyle",
    "weekStart",
    "year"
  ],

  data: () => ({
    shouldRender: false
  }),

  mounted() {
    this.calendar = new JsCalendar(this.$el, {
      allowOverlap: true,
      alwaysHalfDay: this.alwaysHalfDay,
      contextMenuItems: this.contextMenuItems,
      customDayRenderer: this.customDayRenderer,
      customDataSourceRenderer: this.customDataSourceRenderer,
      dataSource: this.dataSource,
      disabledDays: this.disabledDays,
      disabledWeekDays: this.disabledWeekDays,
      displayDisabledDataSource: this.displayDisabledDataSource,
      displayHeader: this.displayHeader,
      displayWeekNumber: this.displayWeekNumber,
      enableContextMenu: this.enableContextMenu,
      enableRangeSelection: this.enableRangeSelection,
      hiddenWeekDays: this.hiddenWeekDays,
      language: "de",
      loadingTemplate: this.loadingTemplate,
      maxDate: this.maxDate,
      minDate: this.minDate,
      roundRangeLimits: this.roundRangeLimits,
      style: this.renderStyle,
      weekStart: this.weekStart,
      startYear: this.year,

      // Events
      clickDay: e => this.$emit("click-day", e),
      dayContextMenu: e => this.$emit("day-context-menu", e),
      mouseOnDay: e => this.$emit("mouse-on-day", e),
      mouseOutDay: e => this.$emit("mouse-out-day", e),
      renderEnd: e => this.$emit("render-end", e),
      selectRange: e => this.$emit("select-range", e),
      yearChanged: e => this.$emit("year-changed", e)
    });
  },

  computed: {
    allProps: function() {
      return `
                ${this.allowOverlap}
                ${this.alwaysHalfDay}
                ${this.contextMenuItems}
                ${this.customDayRenderer}
                ${this.customDataSourceRenderer}
                ${this.dataSource}
                ${this.disabledDays}
                ${this.disabledWeekDays}
                ${this.displayDisabledDataSource}
                ${this.displayHeader}
                ${this.displayWeekNumber}
                ${this.enableContextMenu}
                ${this.enableRangeSelection}
                ${this.hiddenWeekDays}
                ${this.language}
                ${this.loadingTemplate}
                ${this.maxDate}
                ${this.minDate}
                ${this.roundRangeLimits}
                ${this.renderStyle}
                ${this.weekStart}
                ${this.year}
            `;
    }
  },

  watch: {
    allowOverlap: function(val) {
      this.calendar.setAllowOverlap(val);
    },
    alwaysHalfDay: function(val) {
      this.calendar.setAlwaysHalfDay(val, true);
      this.shouldRender = true;
    },
    contextMenuItems: function(val) {
      this.calendar.setContextMenuItems(val, true);
      this.shouldRender = true;
    },
    customDayRenderer: function(val) {
      this.calendar.setCustomDayRenderer(val, true);
      this.shouldRender = true;
    },
    customDataSourceRenderer: function(val) {
      this.calendar.setCustomDataSourceRenderer(val, true);
      this.shouldRender = true;
    },
    dataSource: function(val) {
      this.calendar.setDataSource(val, true);
      this.shouldRender = true;
    },
    disabledDays: function(val) {
      this.calendar.setDisabledDays(val, true);
      this.shouldRender = true;
    },
    disabledWeekDays: function(val) {
      this.calendar.setDisabledWeekDays(val, true);
      this.shouldRender = true;
    },
    displayDisabledDataSource: function(val) {
      this.calendar.setDisplayDisabledDataSource(val, true);
      this.shouldRender = true;
    },
    displayHeader: function(val) {
      this.calendar.setDisplayHeader(val, true);
      this.shouldRender = true;
    },
    displayWeekNumber: function(val) {
      this.calendar.setDisplayWeekNumber(val, true);
      this.shouldRender = true;
    },
    enableContextMenu: function(val) {
      this.calendar.setEnableContextMenu(val, true);
      this.shouldRender = true;
    },
    enableRangeSelection: function(val) {
      this.calendar.setEnableRangeSelection(val, true);
      this.shouldRender = true;
    },
    hiddenWeekDays: function(val) {
      this.calendar.setHiddenWeekDays(val, true);
      this.shouldRender = true;
    },
    language: function(val) {
      this.calendar.setLanguage(val, true);
      this.shouldRender = true;
    },
    loadingTemplate: function(val) {
      this.calendar.setLoadingTemplate(val, true);
    },
    maxDate: function(val) {
      this.calendar.setMaxDate(val, true);
      this.shouldRender = true;
    },
    minDate: function(val) {
      this.calendar.setMinDate(val, true);
      this.shouldRender = true;
    },
    roundRangeLimits: function(val) {
      this.calendar.setRoundRangeLimits(val, true);
      this.shouldRender = true;
    },
    renderStyle: function(val) {
      this.calendar.setStyle(val, true);
      this.shouldRender = true;
    },
    weekStart: function(val) {
      this.calendar.setWeekStart(val, true);
      this.shouldRender = true;
    },
    year: function(val) {
      this.calendar.setYear(val);
    },
    allProps: function() {
      if (this.shouldRender) {
        this.calendar.render();
        this.shouldRender = false;
      }
    }
  },

  locales: JsCalendar.locales // Map the "locales" property to the js-year-calendar "locales" property, in order to make the locale files compatible
};
</script>

<style lang="scss">
.calendar .months-container {
  display: flex !important;
  flex-direction: row;
  flex-wrap: wrap;

  .month-container {
    height: 260px !important;
    page-break-inside: avoid;
  }
}

.calendar .calendar-header {
  background-color: #f5f5f5;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  border: 0;
}

.calendar .calendar-header .year-title {
  font-size: 18px;
}

.calendar
  .calendar-header
  .year-title:not(.year-neighbor):not(.year-neighbor2) {
  border-bottom: 2px solid #004b9c;
}

.calendar .months-container .month-container {
  height: 260px;
  margin-bottom: 25px;
}

.calendar table.month {
  background-color: white;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  height: 100%;
}

.calendar table.month th.month-title {
  background-color: #004b9c;
  color: white;
  padding: 12px;
  font-weight: 400;
}

.calendar table.month th.day-header {
  padding-top: 10px;
  color: #cdcdcd;
  font-weight: 400;
  font-size: 12px;
}

.calendar table.month td.day .day-content {
  padding: 8px;
  border-radius: 100%;
}

.calendar table.month td.day.range.range-start .day-content {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 100%;
  border-bottom-left-radius: 100%;
}

.calendar table.month td.day.range.range-end .day-content {
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.calendar table.month td.day.range .day-content {
  border-radius: 0;
//   border-top: 2px solid rgba(25, 38, 114, 0.397);
//   border-bottom: 2px solid rgba(25, 38, 114, 0.397);
  background: rgba(25, 38, 114, 0.397);
}
.calendar table.month td.day.range.range-start.range-end .day-content {
    border-radius: 100%;
}
</style>
