<template>
  <div class="map-wrapper">
    <l-map :information="information" ref="map" style="z-index: 0" />
    <v-card class="map__info-bar" v-if="hasCaptain">
      <v-card-text>
        <v-container fluid class="pa-0">
          <v-row>
            <v-col class="flex-grow-0 py-1 px-2">
              <user-avatar :user="information.captain" size="50px" />
            </v-col>
            <v-col class="pt-2 pb-0">
              Kapitän<br />
              {{ information.captain.name }}
            </v-col>
            <v-col class="pt-2 pb-0" v-if="false && information.trip">
              Ausflug:<br />
              <router-link
                :to="{ name: 'Trip', arams: { id: information.trip.id } }"
              >
                {{ information.trip.name }}
              </router-link>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <v-menu top offset-y v-if="!hasCaptain || iAmTheCaptain">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          absolute
          :color="isLive ? 'success' : 'primary'"
          fab
          v-bind="attrs"
          v-on="on"
          style="z-index: 500; bottom: 10px; right: 10px;"
        >
          <v-icon>mdi-map-marker</v-icon>
        </v-btn>
      </template>
      <v-list>
        <v-list-item
          @click="liveDialog.show = true"
          v-if="!isLive && !iAmTheCaptain"
        >
          <v-list-item-title>Ausflug starten</v-list-item-title>
        </v-list-item>
        <v-list-item @click="resume()" v-if="!isLive && iAmTheCaptain">
          <v-list-item-title>Ausflug weiterführen</v-list-item-title>
        </v-list-item>
        <v-list-item @click="pause()" v-if="isLive && iAmTheCaptain">
          <v-list-item-title>Ausflug pausieren</v-list-item-title>
        </v-list-item>
        <v-list-item @click="stopLive()" v-if="iAmTheCaptain">
          <v-list-item-title>Ausflug beenden</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-dialog v-model="liveDialog.show" max-width="550px">
      <v-card>
        <v-toolbar color="primary" dark elevation="0">
          Ausflug starten
          <v-spacer />
          <v-btn
            @click="liveDialog.show = false"
            color="error"
            elevation="0"
            fab
            small
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container fluid>
            <v-row v-if="currentTrips.length > 0 && !liveDialog.startTrip">
              <v-col class="pb-0">
                <v-select
                  :items="selectOptions"
                  item-text="name"
                  item-value="id"
                  label="Vorhanden Ausflug auswählen"
                  v-model="liveDialog.trip"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0">
                <v-checkbox
                  label="Ausflug im Planer eintragen?"
                  v-model="liveDialog.startTrip"
                />
              </v-col>
            </v-row>
            <v-row v-if="liveDialog.startTrip">
              <v-col :cols="12">
                <v-text-field label="Name" v-model="liveDialog.name" />
              </v-col>
              <v-col :cols="12">
                <v-textarea
                  label="Beschreibung"
                  rows="3"
                  style="font-size: 1.2em;"
                  v-model="liveDialog.comment"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-btn
                  @click="tryToStartLive"
                  color="primary"
                  elevation="0"
                  :loading="liveDialog.loading"
                >
                  Start
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import information from "@/api/information";
import trips from "@/api/trips";

const options = {
  enableHighAccuracy: true,
  timeout: 30000,
  maximumAge: 0
};

import LMap from "@/components/Map";
import UserAvatar from "@/components/UserAvatar.vue";

export default {
  name: "Map",

  components: {
    LMap,
    UserAvatar
  },

  data: () => ({
    currentTrips: [],
    hasCaptain: false,
    information: {},
    latest: null,
    liveDialog: {
      name: null,
      comment: null,
      loading: false,
      startTrip: false,
      show: false,
      trip: null
    },
    isLive: false,
    isLiveInterval: null
  }),

  computed: {
    iAmTheCaptain() {
      return (
        this.information.captain &&
        this.information.captain.id == this.$auth.user.id
      );
    },
    selectOptions() {
      return [
        {
          id: null,
          name: ""
        },
        ...this.currentTrips
      ];
    }
  },

  created() {
    this.getInformation();
    trips.current().then(response => (this.currentTrips = response.data.data));
  },

  beforeDestroy() {
    if (this.isLiveInterval) {
      try {
        clearInterval(this.isLiveInterval);
      } catch (e) {
        console.log(e);
      }
      try {
        navigator.geolocation.clearWatch(this.isLiveInterval);
      } catch (e) {
        console.log(e);
      }
      this.isLiveInterval = null;
    }
  },

  methods: {
    addInformation(data) {
      console.log('addInformation', data);
      this.information = {
        ...this.information,
        entries: [data, ...(this.information.entries || [])]
      };
      setTimeout(() => this.$refs.map.addToPolyline([data.lat, data.lng]), 250);
      this.$refs.map.moveMerlin([data.lat, data.lng]);
    },
    getInformation() {
      information.get().then(response => {
        const data = response.data;
        this.setInformation(data);
        if (!this.isLiveInterval && !this.iAmTheCaptain) {
          this.isLiveInterval = setInterval(
            () => this.getMoreInformation(),
            60000
          );
        }
      });
    },
    getMoreInformation() {
      information.get(this.latest).then(response => {
        if (response.status === 204) return;
        const data = response.data;
        this.$set(this.information, "entries", [
          data,
          ...this.information.entries
        ]);
        this.$refs.map.addToPolyline([data.lat, data.lng]);
        this.$refs.map.moveMerlin([data.lat, data.lng]);
        this.latest = data.created_at;
        this.hasCaptain = data.captain != null;
      });
    },
    pause() {
      this.isLive = false;
      // clearInterval(this.isLiveInterval);
      navigator.geolocation.clearWatch(this.isLiveInterval);
      this.isLiveInterval = null;
    },
    resume() {
      if (this.isLiveInterval) {
        clearTimeout(this.isLiveInterval);
        this.isLiveInterval = null;
      }
      this.isLive = true;
      // this.sendInformation();
      // this.isLiveInterval = setInterval(() => this.sendInformation(), 60000);
      this.isLiveInterval = navigator.geolocation.watchPosition(
        this.sendWatchInformation,
        () => null,
        options
      );
    },
    sendInformation() {
      navigator.geolocation.getCurrentPosition(
        position =>
          information
            .update({
              lat: position.coords.latitude,
              lng: position.coords.longitude
            })
            .then(response => {
              const data = response.data;
              this.addInformation(data);
            }),
        () => null,
        options
      );
    },
    sendWatchInformation(position) {
      information
        .update({
          lat: position.coords.latitude,
          lng: position.coords.longitude
        })
        .then(response => {
          const data = response.data;
          this.addInformation(data);
        });
    },
    setInformation(data) {
      this.information = { ...data };
      this.latest = data.created_at;
      this.hasCaptain = data.captain != null;
      this.$refs.map.addMerlin(data);
      if (!this.hasCaptain) {
        setTimeout(() => this.$refs.map.flyTo(data, 14, { duration: 3 }), 1000);
      } else {
        this.$refs.map.setPolyline(
          data.entries
            .map(entry => [entry.lat, entry.lng])
            .concat([[data.lat, data.lng]])
        );
      }
    },
    startLive(_event, position) {
      this.liveDialog.loading = false;
      this.liveDialog.show = false;
      const tripData = this.liveDialog.startTrip
        ? { name: this.liveDialog.name, comment: this.liveDialog.comment }
        : { id: this.liveDialog.trip };
      information
        .set({
          ...tripData,
          lat: position.coords.latitude,
          lng: position.coords.longitude
        })
        .then(response => {
          const data = response.data;
          this.pause();
          this.$toast("Los gehts!");
          this.$wakeLock.enable();
          this.isLive = true;
          // this.isLiveInterval = setInterval(
          //   () => this.sendInformation(),
          //   60000
          // );
          this.isLiveInterval = navigator.geolocation.watchPosition(
            this.sendWatchInformation,
            () => null,
            options
          );
          this.setInformation(data);
        })
        .catch(e => {
          console.error(e);
          this.$toast.error("Da lief was schief");
        });
    },
    stopLive() {
      // clearInterval(this.isLiveInterval);
      navigator.geolocation.clearWatch(this.isLiveInterval);
      this.$wakeLock.disable();
      navigator.geolocation.getCurrentPosition(
        position =>
          information
            .stop({
              lat: position.coords.latitude,
              lng: position.coords.longitude
            })
            .then(response => {
              const data = response.data;
              this.isLive = false;
              this.hasCaptain = false;
              this.setInformation(data);
              this.$refs.map.removePolyline();
              this.$toast("Ausflug beendet!");
            }),
        () => null,
        options
      );
    },
    tryToStartLive(event) {
      if (navigator.geolocation) {
        this.liveDialog.loading = true;
        this.$toast({
          text: "Versuche position zu finden...",
          duration: 5000,
          color: "primary"
        });
        navigator.geolocation.getCurrentPosition(
          position => this.startLive(event, position),
          error => {
            this.liveDialog.loading = false;
            switch (error.code) {
              case 1:
                this.$toast.error("Du musst das Geotracking schon erlauben!");
                break;
              case 2:
                this.$toast.error(
                  "Es gab einen internen Fehler bei der Positions bestimmung"
                );
                break;
              case 3:
                this.$toast.error(
                  "Timeout. Versuche es nochmal mit besserem Empfang!"
                );
                break;
              default:
                break;
            }
          },
          {
            enableHighAccuracy: true,
            maximumAge: 1000, // last minute
            timeout: 60000
          }
        );
      } else {
        this.$toast.error(
          "Live tracking kann auf diesem Gerät nicht erfolgen!"
        );
      }
    }
  }
};
</script>

<style>
.map-wrapper {
  max-height: calc(var(--vh) * 100);
  height: calc(var(--vh) * 100);
  position: relative;
}
.map__info-bar {
  z-index: 501;
  position: absolute;
  top: 10px;
  left: 55px;
  background: #fff;
}
</style>
