<template>
  <div>
    <year-calendar
      @click-day="onClickDay($event)"
      :dataSource="events"
      @day-context-menu="onDayContextMenu($event)"
      :enableContextMenu="true"
      :enableRangeSelection="true"
      @select-range="onSelectRange($event)"
      :weekStart="1"
      v-if="dispalyCalendar"
      @year-changed="getEvents($event)"
    />
    <v-dialog v-model="modal" v-if="modal" max-width="550px">
      <v-card>
        <v-toolbar class="text-h5" color="primary" dark elevation="0">
          Neuen Ausflug erstellen
          <v-spacer />
          <v-btn @click="modal = false" color="error" fab small elevation="0">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pt-5">
          <tour-form ref="modelForm" @add="addTour($event)" :model="model" />
        </v-card-text>
      </v-card>
    </v-dialog>
    <trip-viewer
      @destroyed="removeTrip()"
      ref="tripViewer"
      :trips="tripViewer.model"
      v-if="tripViewer.model"
      v-model="tripViewer.show"
    />
  </div>
</template>

<script>
import tours from "@/api/tours";
import trips from "@/api/trips";
import YearCalendar from "@/components/Calendar.vue";
import TourForm from "@/components/TourForm.vue";
import TripViewer from "@/components/TripViewer.vue";

export default {
  name: "Calendar",

  components: {
    TourForm,
    YearCalendar,
    TripViewer
  },

  data: () => ({
    dispalyCalendar: false,
    events: [],
    modal: false,
    model: {},
    trips: [],
    tripViewer: {
      show: false,
      model: null
    },
    tours: [],
    tooltips: {},
    year: null
  }),

  created() {
    this.year = new Date().getFullYear();
    this.getEvents({ currentYear: this.year });
  },

  methods: {
    addTour() {
      this.getEvents({ currentYear: this.year });
    },
    getEvents(year) {
      return Promise.all([
        this.getTrips(year.currentYear),
        this.getTours(year.currentYear)
      ]).then(values => {
        this.events = values.flat(1);
        this.dispalyCalendar = true;
        return values.flat(1);
      });
    },
    getTrips(year) {
      if (!year) return;
      return trips.get(year).then(response => {
        this.trips = response.data.data.map(r => ({
          startDate: new Date(r.date_from),
          endDate: new Date(r.date_to),
          color: r.user.color || "#888888",
          // Arbitrary data
          name: r.name || "NAME",
          details: r.comment || "BESCHREIBUNG",
          user: r.user,
          id: r.id
        }));
        return this.trips;
      });
    },
    getTours(year) {
      if (!year) return;
      return tours.get(year).then(response => {
        this.tours = response.data.data.map(r => ({
          startDate: new Date(r.date_from),
          endDate: new Date(r.date_to),
          color: r.user.color || "#888888",
          // Arbitrary data
          name: r.name || "NAME",
          details: r.comment || "BESCHREIBUNG",
          user: r.user,
          id: r.id
        }));
        return this.tours;
      });
    },
    onClickDay(e) {
      console.log(e);
    },
    onDayContextMenu(e) {
      console.log(e);
    },
    onSelectRange(e) {
      const startDate = new Date(
        Date.UTC(
          e.startDate.getFullYear(),
          e.startDate.getMonth(),
          e.startDate.getDate()
        )
      );
      const endDate = new Date(
        Date.UTC(
          e.endDate.getFullYear(),
          e.endDate.getMonth(),
          e.endDate.getDate()
        )
      );
      const hasCollidingTrips = this.trips.filter(
        trip =>
          trip.startDate.getTime() <= endDate.getTime() &&
          trip.endDate.getTime() >= startDate.getTime()
      );
      // const hasCollidingTours = this.tours.find(
      //   tour =>
      //     tour.startDate.getTime() <= endDate.getTime() &&
      //     tour.endDate.getTime() >= startDate.getTime()
      // );
      if (hasCollidingTrips.length > 0) {
        this.tripViewer.model = hasCollidingTrips;
        this.tripViewer.show = true;
        return;
      }
      this.model.canTrip = !hasCollidingTrips;
      // this.model.canTour = !hasCollidingTours;
      this.model.date_from = `${e.startDate.getFullYear()}-${String(
        e.startDate.getMonth() + 1
      ).padStart(2, "0")}-${String(e.startDate.getDate()).padStart(2, "0")}`;
      this.model.date_to = `${e.endDate.getFullYear()}-${String(
        e.endDate.getMonth() + 1
      ).padStart(2, "0")}-${String(e.endDate.getDate()).padStart(2, "0")}`;
      this.modal = true;
    },
    removeTrip() {
      this.trips = this.trips.filter(
        trip => trip.id !== this.tripViewer.model.id
      );
      this.events = this.trips.concat(this.tours);
    }
  }
};
</script>
