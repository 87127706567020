<template>
  <div id="map" />
  <!-- <div id="wrapper">
    <div id="routes">
      <div style="display: flex; flex-direction: row;">
        <img
          alt="Merlin"
          :src="require('@/assets/logo.svg')"
          style="height: 100px; margin: 10px 0 0 10px;"
        />
        <div style="width: 100%; margin-top: 10px;">
          <a
            style="cursor: pointer; color: #004b9c;"
            @click.prevent="setInformation()"
            >Aktuelle Koordinaten übernehmen</a
          >
          <br />
          <br />
          <a
            style="cursor: pointer; color: #004b9c;"
            @click.prevent="modal = true"
            >Neue Route planen</a
          >
        </div>
      </div>
      <div id="routes-list">
        <div
          class="route-list-entry"
          :key="route.id"
          @mouseenter="onMouseEnter(route.id)"
          @mouseleave="onMouseLeave(route.id)"
          v-for="route in sortedRoutes"
        >
          <div class="route-list-entry-delete" @click="deleteRoute(route.id)">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
            >
              <path
                d="M17 2h-3.5l-1-1h-5l-1 1H3v2h14zM4 17a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V5H4z"
                fill="#aa0000"
              />
            </svg>
          </div>
          <h4 class="is-size-5 has-text-weight-semibold">
            {{ route.date_from }} - {{ route.date_to }}
          </h4>
          <p class="has-text-weight-semibold">{{ route.name }}</p>
          <p v-if="route.comment">{{ route.comment }}</p>
        </div>
      </div>
    </div>
    <div id="map" />
  </div>
  <div id="modal" v-show="modal">
    <div id="modal-bg" />
    <div id="modal-content">
      <a
        @click.prevent="
          $refs.modelForm.reset();
          modal = false;
        "
        id="modal-close"
        >X</a
      >
      <route-form ref="modelForm" @add="addRoute($event)" />
    </div>
  </div> -->
</template>

<script>
export default {
  name: "Map",

  data: () => ({
    colors: [
      "#37474f",
      "#ff7043",
      "#aeea00",
      "#00e676",
      "#0091ea",
      "#651fff",
      "#d50000",
      "#ffea00",
      "#212121",
      "#42a5f5",
      "#bcaaa4"
    ],
    icon: null,
    marker: null,
    map: null,
    modal: false,
    polyline: null,
    polylines: {},
    routes: []
  }),

  computed: {
    sortedRoutes() {
      return this.routes.slice().sort((a, b) => {
        return a.date_from.replace(/(\d{2})\.(\d{2})\.(\d{4})/, "$3-$2-$1") >
          b.date_from.replace(/(\d{2})\.(\d{2})\.(\d{4})/, "$3-$2-$1")
          ? 1
          : -1;
      });
    }
  },

  created() {
    this.icon = this.$leaflet.icon({
      iconUrl: require("@/assets/marker.png"),
      iconAnchor: [25, 17.5]
    });
  },

  mounted() {
    this.map = this.$leaflet.map("map").setView([50.5419852, 9.3368223], 7);
    const url =
      window.webpackHotUpdate ||
      (process.env.NODE_ENV !== "production" &&
        process.env.NODE_ENV !== "test" &&
        typeof console !== "undefined")
        ? "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        : "/openstreetmap/{z}/{x}/{y}.png";
    this.$leaflet
      .tileLayer(url, {
        maxZoom: 19,
        attribution:
          '&copy; OSM Mapnik <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
      })
      .addTo(this.map);
  },

  methods: {
    addMerlin(data) {
      if (this.marker != null) return this.moveMerlin([data.lat, data.lng]);
      this.marker = this.$leaflet
        .marker([data.lat, data.lng], { icon: this.icon })
        .addTo(this.map);
      this.map.invalidateSize();
    },
    addToPolyline(point) {
      const latLng = this.$leaflet.latLng(...point);
      this.polyline.addLatLng(latLng);
      setTimeout(() => this.map.fitBounds(this.polyline.getBounds()), 150);
    },
    flyTo(data, zoom = 16, opts = { duration: 5 }) {
      this.map.flyTo(this.$leaflet.latLng([data.lat, data.lng]), zoom, opts);
    },
    invalidateSize() {
      if (this.polyline) {
        return setTimeout(() => {
          this.map.invalidateSize();
          this.map.fitBounds(this.polyline.getBounds());
        }, 500);
      }
      setTimeout(() => this.map.invalidateSize(), 250);
    },
    moveMerlin(data, opts = {}) {
      console.log('Move Merlin', data);
      const latLng = this.$leaflet.latLng(...data);
      this.marker.setLatLng(latLng);
      if (opts.withLine) {
        this.polyline = [...this.polyline];
      }
    },
    onMouseEnter(id) {
      this.polylines[id].setStyle({
        opacity: 1
      });
    },
    onMouseLeave(id) {
      this.polylines[id].setStyle({
        opacity: 0.5
      });
    },
    redrawPolylines() {
      Object.values(this.polylines).map(polyline => polyline.remove(this.map));
      let colorIndex = 0;
      this.routes.map(route => {
        if (!this.colors[colorIndex]) colorIndex = 0;
        let pointList = [];
        for (let index = 1; index < route.gpx.length; index++) {
          const from = this.$leaflet.latLng(route.gpx[index - 1]);
          const to = this.$leaflet.latLng(route.gpx[index]);
          pointList.push([from, to]);
        }
        const polyLine = this.$leaflet
          .polyline(pointList, {
            color: this.colors[colorIndex],
            weigth: 1,
            opacity: 0.5,
            smoothFactor: 1
          })
          .addTo(this.map);
        this.polylines[route.id] = polyLine;
        colorIndex++;
      });
    },
    removePolyline() {
      if (this.polyline) this.polyline.remove(this.map);
    },
    setPolyline(array, opts = { color: "red" }) {
      if (this.polyline) this.polyline.remove(this.map);
      this.polyline = this.$leaflet.polyline(array, opts);
      this.polyline.addTo(this.map);
    }
  }
};
</script>

<style>
#map {
  height: max(800px, calc(var(--vh) * 100));
  width: 100%;
}
#wrapper {
  display: flex;
  flex-direction: row;
}
#routes {
  width: 400px;
}
#routes-list {
  text-align: left;
  padding: 10px 5px;
  max-height: calc(100vh - 120px);
  max-height: calc(var(--vh, 1vh) * 100 - 120px);
  overflow: auto;
}
.route-list-entry {
  position: relative;
}
.route-list-entry:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.7);
}
.route-list-entry-delete {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}
#modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
}
#modal-bg {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1;
}
#modal-content {
  text-align: left;
  width: max(50vw, 400px);
  height: min(600px, 90vh);
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  background-color: #fff;
  z-index: 2;
  padding: 30px 20px 20px 20px;
  border-radius: 5px;
  overflow: auto;
}
#modal-close {
  position: absolute;
  top: 5px;
  right: 10px;
  font-size: 2em;
  font-weight: 900;
  color: red;
  cursor: pointer;
}
</style>
