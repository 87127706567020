<template>
  <chat-window
    ref="window"
    height="calc(var(--vh) * 100)"
    :current-user-id="currentUserId"
    :rooms="rooms"
    :messages="messages"
    :messagesLoaded="messagesLoaded"
    @open-file="openFile"
    @send-message="sendMessage"
    :show-files="true"
    :single-room="true"
    :text-messages="{
      ROOMS_EMPTY: 'Keine Nachrichten',
      ROOM_EMPTY: 'Keine Nachrichten',
      NEW_MESSAGES: 'Neue Nachrichten',
      MESSAGE_DELETED: 'Gelöschte Nachricht',
      MESSAGES_EMPTY: 'Keine Nachrichten',
      CONVERSATION_STARTED: 'Beginn der Unterhaltung :',
      TYPE_MESSAGE: 'Nachricht schreiben',
      SEARCH: 'Suchen',
      IS_ONLINE: 'ist online',
      LAST_SEEN: 'zuletzt gesehen ',
      IS_TYPING: 'schreibt...'
    }"
  >
    <template v-slot:room-header-info="">
      ASDASDASDASD
    </template>
  </chat-window>
</template>

<script>
import ChatWindow from "vue-advanced-chat";
import "vue-advanced-chat/dist/vue-advanced-chat.css";
import api from "@/api/chat";

export default {
  components: {
    ChatWindow
  },
  data() {
    return {
      messages: [],
      messagesLoaded: false,
      roomId: 1,
      rooms: [
        {
          roomId: 1,
          roomName: "Hafengesabbel",
          avatar: require("@/assets/chat_avatar.png"),
          unreadCount: 0,
          index: 0,
          lastMessage: {},
          users: [
            // {
            //   _id: 1234,
            //   username: "John Doe",
            //   avatar: "assets/imgs/doe.png",
            //   status: {
            //     state: "online",
            //     lastChanged: "today, 14:30"
            //   }
            // },
          ],
          typingUsers: []
        }
      ],
      roomsLoaded: true
    };
  },

  computed: {
    currentUserId() {
      return this.$auth.user.id;
    }
  },

  mounted() {
    api.users().then(response => {
      this.$set(this.rooms[0], "users", response.data.data);
    });
    api.messages().then(response => {
      this.$set(this, "messages", response.data.data);
      this.messagesLoaded = true;
    });
    const room = this.$refs.window.$children[0];
    room.launchFilePicker = () => {
      room.$refs.file.value = "";
      let evt = document.createEvent("MouseEvents");
      evt.initEvent("click", true, false);
      room.$refs.file.dispatchEvent(evt);
      // room.$refs.file.click();
    };
  },

  methods: {
    openFile({ message }) {
      window.open(message.file.url, "_blank");
    },
    async sendMessage({ content, file, replyMessage }) {
      console.log({ content, file, replyMessage });
      // For AudioFile the content is empty
      const message = {
        sender_id: this.currentUserId,
        content,
        timestamp: new Date()
      };
      if (file) {
        message.file = {
          name: file.name,
          size: file.size,
          type: file.type,
          extension: file.extension || file.type,
          url: file.localUrl
        };
        if (file.audio) {
          message.file.audio = true;
          message.file.duration = file.duration;
        }
      }
      if (replyMessage) {
        message.replyMessage = {
          _id: replyMessage._id,
          content: replyMessage.content,
          sender_id: replyMessage.senderId
        };
        if (replyMessage.file) {
          message.replyMessage.file = replyMessage.file;
        }
      }
      const response = await api.sendMessage(message);

      if (file)
        return this.uploadFile({ file, messageId: response.data.data.id });

      this.messages = [...this.messages, response.data.data];
      //   const { id } = await messagesRef(roomId).add(message);
      //   if (file) this.uploadFile({ file, messageId: id, roomId });
      //   roomsRef.doc(roomId).update({ lastUpdated: new Date() });
    },
    uploadFile({ file, messageId }) {
      let type = file.extension || file.type;
      if (type === "svg" || type === "pdf") {
        type = file.type;
      }

      const data = new FormData();
      data.append("file", file.blob);

      api
        .attachFile(messageId, data)
        .then(
          response => (this.messages = [...this.messages, response.data.data])
        );
    }
  }
};
</script>

<style lang="scss">
.vac-card-window {
  max-width: 100vw !important;
}
.vac-room-header {
  display: none !important;
  height: 0 !important;
  max-height: 0 !important;
}
.vac-container-scroll {
  margin-top: 0px !important;
}
</style>
