<template>
  <svg
    :width="46.708 / 61.464 * parseFloat(size, 10) + 'em'"
    :height="size"
    version="1.1"
    viewBox="0 0 46.708 61.464"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    style="margin-bottom: -0.3em;"
  >
    <g transform="translate(-100.64 -96.084)">
      <path
        :fill="color"
        d="m100.64 96.943h19.085l7.7139 9.3726 9.2102-10.231 10.698 12.101v49.362l-11.601-10.733v-31.923l-8.2131 8.0773-8.3832-8.3844v30.839h-11.65v-40.535z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "M",

  props: {
    color: {
      type: String,
      default: "#000000"
    },
    size: {
        type: String,
        default: "1em"
    }
  }
};
</script>
