<template>
  <div
    class="harbor-wrapper"
    :style="{
      height: `calc(var(--vh) * 100 - ${
        !$vuetify.breakpoint.mdAndUp ? '0px' : '0px'
      })`
    }"
  >
    <div
      class="harbor"
      id="harbor"
      @click.prevent="onClick"
      @dblclick="onDoubleClick"
      @drop.prevent="onDrop"
      @dragover.prevent
      @mousemove.passive="onMouseMove"
      ref="harbor"
      v-longpress="onLongPress"
    >
      <input
        ref="file"
        type="file"
        accept="image/*"
        style="display:none"
        @change="onFileChange($event.target.files)"
      />
      <div
        class="gallery_bg"
        :style="{
          backgroundImage: 'url(' + require('@/assets/gallery_bg.png') + ')'
        }"
      />
      <div
        style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; overflow: hidden;"
      >
        <div
          class="gallery_image"
          :key="img.id"
          :style="{
            backgroundImage:
              'url(/storage/pub/' + img.id + '.jpg?t=' + img.updated_at + ')',
            top: `${containers[img.id][0][1]}px`,
            left: `${containers[img.id][0][0]}px`,
            width: `${containers[img.id][1][0] - containers[img.id][0][0]}px`,
            height: `${containers[img.id][1][1] - containers[img.id][0][1]}px`
          }"
          v-for="img in images"
        />
      </div>
      <div
        class="gallery_preview"
        :style="{
          backgroundImage: 'url(' + image + ')',
          top: `${container[1][0][1]}px`,
          left: `${container[1][0][0]}px`,
          width: `${container[1][1][0] - container[1][0][0]}px`,
          height: `${container[1][1][1] - container[1][0][1]}px`
        }"
        v-if="step == steps['choosePlace'] && container"
      />
      <div
        class="gallery_fg"
        :style="{
          backgroundImage: 'url(' + require('@/assets/gallery_bg_cut.png') + ')'
        }"
      />
      <a class="gallery_file_input" @click="launchFilePicker()" />
    </div>
  </div>
</template>

<script>
import panzoom from "panzoom";
import api from "@/api/pub";

export default {
  name: "Start",

  data: () => ({
    container: null,
    containers: {
      1: [
        [292, 82],
        [669, 346]
      ],
      2: [
        [702, 196],
        [897, 477]
      ],
      3: [
        [915, 209],
        [1269, 473]
      ],
      4: [
        [1268, 245],
        [1449, 498]
      ],
      5: [
        [1451, 114],
        [1757, 345]
      ],
      6: [
        [1481, 373],
        [1683, 541]
      ],
      7: [
        [1703, 402],
        [1867, 531]
      ],
      8: [
        [164, 362],
        [412, 747]
      ],
      9: [
        [466, 391],
        [672, 699]
      ],
      10: [
        [712, 492],
        [891, 731]
      ],
      11: [
        [958, 481],
        [1231, 724]
      ],
      12: [
        [496, 733],
        [764, 951]
      ],
      13: [
        [772, 753],
        [964, 1021]
      ],
      14: [
        [965, 737],
        [1211, 947]
      ],
      15: [
        [1265, 567],
        [1511, 942]
      ],
      16: [
        [1569, 605],
        [1757, 944]
      ]
    },
    file: null,
    image: null,
    images: [],
    panzoom: null,
    step: 0,
    steps: { chooseFront: 1, choosePlace: 2 }
  }),

  created() {
    api.load().then(response => {
      this.images = response.data.data;
    });
  },

  mounted() {
    const el = this.$refs.harbor;
    this.panzoom = panzoom(el, {
      onTouch: function() {
        return false; // tells the library to not preventDefault.
      }
    });
  },

  beforeDestroy() {
    this.panzoom.dispose();
  },

  methods: {
    findContainer(e) {
      return Object.entries(this.containers).find(([_key, value]) => {
        return (
          e.offsetX > value[0][0] &&
          e.offsetX < value[1][0] &&
          e.offsetY > value[0][1] &&
          e.offsetY < value[1][1]
        );
      });
    },
    insert(image) {
      console.log(image);
      const idx = this.images.findIndex(img => img.id == image.id);
      console.log(idx);
      if (idx >= 0) {
        return this.images.splice(idx, 1, image);
      }
      this.images.push(image);
    },
    launchFilePicker() {
      this.$refs.file.value = "";
      let evt = document.createEvent("MouseEvents");
      evt.initEvent("click", true, false);
      this.$refs.file.dispatchEvent(evt);
      this.$refs.file.click();
    },
    onClick(e) {
      if (this.step == this.steps["choosePlace"]) {
        const container = this.findContainer(e);
        if (container) {
          return (this.container = container);
        }
        this.container = null;
        return;
      }
    },
    onDoubleClick(e) {
      if (this.step == this.steps["choosePlace"]) {
        const container = this.findContainer(e);
        if (!container) return;
        return (this.container = container);
      }
    },
    onDrop(e) {
      const droppedFiles = e.dataTransfer.files;
      if (!droppedFiles) return;
      this.step = this.steps["choosePlace"];
      const imageType = /image.*/;
      if (!droppedFiles[0].type.match(imageType)) {
        return;
      }
      this.file = droppedFiles[0];
      this.image = URL.createObjectURL(droppedFiles[0]);
    },
    async onFileChange(files) {
      this.step = this.steps["choosePlace"];
      const file = files[0];
      this.file = file;
      this.image = URL.createObjectURL(file);
    },
    onLongPress(e) {
      const container = this.findContainer(e);
      if (!this.container || this.container[0] != container[0]) {
        return;
      }
      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("id", container[0]);
      formData.append("coords", container[1]);
      api.store(formData).then(response => {
        this.step = 0;
        this.image = null;
        this.file = null;
        const image = response.data.data;
        this.insert(image);
        this.$toasted.show("Neues Bild aufgehängt", {
          className: "toasted-success is-size-3",
          duration: 5000,
          position: "top-center"
        });
      });
    },
    onMouseMove() {
      if (this.step != this.steps["choosePlace"]) return;
      //   const container = Object.entries(this.containers).find(
      //     ([_key, value]) => {
      //       return (
      //         e.offsetX > value[0][0] &&
      //         e.offsetX < value[1][0] &&
      //         e.offsetY > value[0][1] &&
      //         e.offsetY < value[1][1]
      //       );
      //     }
      //   );
      //   if (container) {
      //     return (this.container = container);
      //   }
      //   this.container = null;
    }
  }
};
</script>

<style lang="scss">
.harbor-wrapper {
  max-width: 100vw;
  overflow: auto;
  background-color: rgb(104, 59, 8);
}
.harbor {
  position: relative;
  min-width: 1900px;
  height: 100%;
  min-height: 1000px;
  overflow: auto;

  .gallery_file_input {
    display: block;
    position: absolute;
    background: rgba(0, 0, 0, 0);
    top: 0;
    left: 990px;
    width: 200px;
    height: 200px;
    z-index: 4;
  }

  .gallery_bg,
  .gallery_fg {
    background-position: top left;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .gallery_bg {
    z-index: 0;
  }
  .gallery_image {
    z-index: 1;
    position: absolute;
    background-size: cover;
    background-position: center center;
  }
  .gallery_preview {
    z-index: 2;
    position: absolute;
    background-size: cover;
    background-position: center center;
  }
  .gallery_fg {
    z-index: 3;
  }
}
.slideIn-enter-active,
.slideIn-leave-active {
  transition: transform 0.5s;
}
.slideIn-enter, .slideIn-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translate3d(-100%, 0, 0);
}
</style>
