<template>
  <v-chat />
</template>

<script>
import VChat from "@/components/Chat";

export default {
  name: "Chat",

  components: {
    VChat
  }
};
</script>
