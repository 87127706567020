var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('chat-window',{ref:"window",attrs:{"height":"calc(var(--vh) * 100)","current-user-id":_vm.currentUserId,"rooms":_vm.rooms,"messages":_vm.messages,"messagesLoaded":_vm.messagesLoaded,"show-files":true,"single-room":true,"text-messages":{
    ROOMS_EMPTY: 'Keine Nachrichten',
    ROOM_EMPTY: 'Keine Nachrichten',
    NEW_MESSAGES: 'Neue Nachrichten',
    MESSAGE_DELETED: 'Gelöschte Nachricht',
    MESSAGES_EMPTY: 'Keine Nachrichten',
    CONVERSATION_STARTED: 'Beginn der Unterhaltung :',
    TYPE_MESSAGE: 'Nachricht schreiben',
    SEARCH: 'Suchen',
    IS_ONLINE: 'ist online',
    LAST_SEEN: 'zuletzt gesehen ',
    IS_TYPING: 'schreibt...'
  }},on:{"open-file":_vm.openFile,"send-message":_vm.sendMessage},scopedSlots:_vm._u([{key:"room-header-info",fn:function(){return [_vm._v(" ASDASDASDASD ")]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }