<template>
  <v-data-table :items="models" :headers="headers"> </v-data-table>
</template>

<script>
import api from "@/api/tripstours";

export default {
  name: "Trips_And_Tours",

  data: () => ({
    headers: [
      { text: "Von", value: "start_date" },
      { text: "Bis", value: "end_date" },
      { text: "Name", value: "name" }
    ],
    models: []
  }),

  created() {
    const year = new Date().getFullYear();
    api.get(year).then(response => {
      const data = response.data.data;
      this.models = [...data];
    });
  }
};
</script>
