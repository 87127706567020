import Vue from "vue";
import VueRouter from "vue-router";

import Calendar from "@/views/Calendar.vue";
import Chat from "@/views/Chat.vue";
import Login from "@/views/Login.vue";
import Manuals from "@/views/Manuals.vue";
import Map from "@/views/Map.vue";
import Register from "@/views/Register.vue";
import Start from "@/views/Start.vue";
import Trip from "@/views/Trip.vue";
import TripsTours from "@/views/TripsTours.vue";
import User from "@/views/User.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/Register/:token",
    name: "Register",
    component: Register,
    meta: {
      guest: true
    }
  },

  {
    path: "/Login",
    name: "Login",
    component: Login,
    meta: {
      guest: true
    }
  },

  {
    path: "/Start",
    name: "Start",
    component: Start
  },

  {
    path: "/Chat",
    name: "Chat",
    component: Chat
  },

  {
    path: "/Manuals",
    name: "Manuals",
    component: Manuals
  },

  {
    path: "/TripsNTours",
    name: "TripsNTours",
    component: TripsTours
  },

  {
    path: "/Trip/:id",
    name: "Trip",
    component: Trip
  },

  {
    path: "/Kalender",
    name: "Kalender",
    component: Calendar
  },

  {
    path: "/Karte",
    name: "Karte",
    component: Map
  },

  {
    path: "/Ich",
    name: "User",
    component: User
  },

  {
    path: "*",
    alias: "/Start"
  }
];

const router = new VueRouter({
  routes
});

let entry = null;

router.beforeEach((to, from, next) => {
  // Redirect after login to intended URL
  if (entry != null && entry.name && router.app.$auth.is) {
    const url = entry;
    entry = null;
    return next(url);
  }

  // Auth check
  if (Object.prototype.hasOwnProperty.call(to, "meta")) {
    if (to.meta.guest !== true && !router.app.$auth.is) {
      entry = to;
      return next({ name: "Login" });
    }
    if (to.meta.guest === true && router.app.$auth.is) {
      return next({ name: "Start" });
    }
  }

  next(true);
});

export default router;
