<template>
  <div class="app__avatar">
    <div
      class="app__avatar-preview"
      :style="{
        'background-image': `url(${user.avatar})`,
        height: size,
        width: size
      }"
      v-if="user && user.avatar"
    />
    <img
      :style="{ height: size, width: size, minWidth: size }"
      :src="require('@/assets/captain.svg')"
      v-else
    />
  </div>
</template>

<script>
export default {
  name: "User_Avatar",

  props: {
    size: {
      default: "80px"
    },
    user: {
      type: Object,
      default: null
    },
  }
};
</script>

<style lang="scss">
.app__avatar {
  > img,
  > div {
    border-radius: 100%;
    height: 100%;
    background-size: cover;
  }
}
</style>
