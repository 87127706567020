import Vue from "vue";

class Auth {
  is() {
    return !!this.user;
  }

  setHttp(http) {
    this.http = http;
  }
}

export default {
  install: (app, options) => {
    if (!options.http)
      throw Error("No http client in options for auth plugin defined.");
    const auth = new Auth();
    auth.setHttp(options.http);

    app.prototype.$auth = Vue.observable({
        http: options.http,
        is: null,
        user: null,
    });
  }
};
