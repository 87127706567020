<template>
  <div class="user-form px-5 pt-5">
    <v-form class="box" @submit.prevent="update()">
      <v-container fluid>
        <v-row>
          <v-col>
            <v-file-input
              accept="image/*"
              @change="onFilePicked($event)"
              label="Eigenes Bild hochladen"
              ref="fileInput"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <div class="user--profile__avatar">
              <div
                class="user--profile__avatar-preview"
                :style="{ 'background-image': `url(${previewImage})` }"
                v-if="previewImage"
              />
              <div
                class="user--profile__avatar-preview"
                :style="{ 'background-image': `url(${user.avatar})` }"
                v-else-if="user.avatar"
              />
              <img :src="require('@/assets/captain.svg')" v-else />
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="Name"
              placeholder="Werner Beinhart"
              required
              v-model="user.name"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
              label="Passwort"
              placeholder="*******"
              required
              type="password"
              v-model="user.password"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn @click="update()" color="success" elevation="0">
              Speichern
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </div>
</template>

<script>
import auth from "@/api/auth";

export default {
  name: "Login",

  components: {},

  data: () => ({
    color: "",
    colors: [
      ["#FF0000"],
      ["#550000"],
      ["#00FF00"],
      ["#FF00FF"],
      ["#0000FF"],
      ["#008888"],
      ["#888833"],
      ["#FF8800"],
      ["#00FFFF"],
      ["#883388"],
      ["#EEEE00"],
      ["#008800"]
    ],
    loading: false,
    previewImage: null,
    user: {},
    valid: false
  }),

  created() {
    this.user = { ...this.$auth.user };
  },

  mounted() {},

  methods: {
    onFilePicked(file) {
      if (!file) return;
      let reader = new FileReader();
      reader.onload = e => {
        this.previewImage = e.target.result;
      };
      reader.readAsDataURL(file);
      this.updateAvatar(file);
    },
    async requestCsrfToken() {
      return auth.csrf();
    },
    async update() {
      if (this.loading) return;
      this.loading = true;
      const formData = new FormData();
      formData.append("_method", "PATCH");
      if (this.user.name != this.$auth.user.name)
        formData.append("name", this.user.name);
      if (this.user.password != this.$auth.user.password)
        formData.append("password", this.user.password);
      await this.requestCsrfToken();
      return auth
        .update(formData)
        .then(response => {
          this.$set(this.$auth, "user", response.data.data);
          this.$toast("Daten erfolgreich geändert!");
        })
        .finally(() => (this.loading = false));
    },
    async updateAvatar(file) {
      const formData = new FormData();
      formData.append("avatar", file);
      formData.append("_method", "PATCH");
      await this.requestCsrfToken();
      return auth
        .update(formData)
        .then(response => {
          this.$set(this.$auth, "user", response.data.data);
          this.$toast("Nutzerbild geändert!");
          this.$refs.fileInput.reset();
          this.$refs.fileInput.blur();
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style lang="scss">
.user-form {
  width: min(350px, 100vw);
  height: 100vh;
  place-items: center center;
  text-align: center;
  margin: auto;
}
.user--profile__avatar {
  width: 350px;
  height: 350px;
  margin-bottom: 20px;

  > img,
  > div {
    border-radius: 100%;
    height: 100%;
    width: 350px;
    height: 350px;
    background-size: cover;
  }
}
.st0 {
  fill-rule: evenodd;
  clip-rule: evenodd;
  fill: #ffffff;
}
</style>
