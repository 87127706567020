<template>
  <v-navigation-drawer
    app
    disable-resize-watcher
    :expand-on-hover="$vuetify.breakpoint.mdAndUp"
    id="app__navigation-drawer"
    :permanent="$vuetify.breakpoint.mdAndUp"
    v-model="$root.drawer"
    :width="$vuetify.breakpoint.mdAndUp ? '250px' : '100vw'"
    stateless
    style="z-index: 90;"
  >
    <v-list nav class="py-0 full-height" id="app__navigation-drawer_list">
      <!-- System ICaaS -->
      <v-list-item @click="$root.drawer = false" class="px-0" dark :to="{ name: 'Start' }">
        <v-list-item-avatar>
          <img alt="ICaaS" :src="require('@/assets/logo_white.svg')" />
        </v-list-item-avatar>
        <v-list-item-content class="py-0" style="color: #ffffff;">
          <div>
            <m-letter
              color="#ffffff"
              size="1.5em"
              style="margin-right: 0.1em;"
            />ERLIN
          </div>
          <v-btn
            @click.prevent="$root.drawer = false"
            icon
            large
            style="position: absolute; top: 5px; right: 5px;"
            v-if="!$vuetify.breakpoint.mdAndUp"
            ><v-icon large>mdi-close</v-icon></v-btn
          >
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="$root.drawer = false" class="px-0" link dark :to="{ name: 'Chat' }">
        <v-list-item-avatar>
          <v-icon>mdi-chat</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title
            ><c-letter
              color="#ffffff"
              size="1.5em"
              style="margin-right: 0.1em;"
            />hat</v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="$root.drawer = false" class="px-0" link dark :to="{ name: 'Kalender' }">
        <v-list-item-avatar>
          <v-icon>mdi-calendar</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title
            ><p-letter
              color="#ffffff"
              size="1.5em"
              style="margin-right: 0.1em;"
            />laner</v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="$root.drawer = false" class="px-0" link dark :to="{ name: 'TripsNTours' }" v-if="false">
        <v-list-item-avatar>
          <v-icon>mdi-map-marker-path</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title
            ><t-letter
              color="#ffffff"
              size="1.5em"
              style="margin-right: 0.1em;"
            />rips 'n' <t-letter
              color="#ffffff"
              size="1.5em"
              style="margin-right: 0.1em;"
            />ours</v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="$root.drawer = false" class="px-0" link dark :to="{ name: 'Karte' }">
        <v-list-item-avatar>
          <v-icon>mdi-map</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title
            ><m-letter
              color="#ffffff"
              size="1.5em"
              style="margin-right: 0.1em;"
            />aps</v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>

      <v-list-item @click="$root.drawer = false" class="px-0" link dark :to="{ name: 'Manuals' }">
        <v-list-item-avatar>
          <v-icon>mdi-file-document-multiple-outline</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title
            ><m-letter
              color="#ffffff"
              size="1.5em"
              style="margin-right: 0.1em;"
            />anuals</v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>

      <v-divider color="white" />

      <v-list-item @click="$root.drawer = false" class="px-0" link dark :to="{ name: 'User' }">
        <v-list-item-avatar>
          <v-icon>mdi-account</v-icon>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title
            ><m-letter
              color="#ffffff"
              size="1.5em"
              style="margin-right: 0.1em;"
            />ein Profil</v-list-item-title
          >
        </v-list-item-content>
      </v-list-item>

      <v-list-item
        link
        dark
        @click="logout()"
        style="position: absolute; bottom: 0; width: 100%;"
      >
        <v-list-item-icon>
          <v-icon>mdi-power</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Wech hier</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import {
  mdiAccountCancel,
  mdiAccountCash,
  mdiAccountCog,
  mdiBadgeAccount,
  mdiBed,
  mdiHeadLightbulbOutline,
  mdiTableAccount,
  mdiTimelineClock
} from "@mdi/js";
import api from "@/api/auth";
import CLetter from "@/components/Letters/C";
import MLetter from "@/components/Letters/M";
import PLetter from "@/components/Letters/P";
import TLetter from "@/components/Letters/T";

export default {
  name: "App_Components_Drawer",

  components: {
    CLetter,
    MLetter,
    PLetter,
    TLetter
  },

  data: () => ({
    icons: {
      mdiAccountCancel,
      mdiAccountCash,
      mdiAccountCog,
      mdiBadgeAccount,
      mdiBed,
      mdiHeadLightbulbOutline,
      mdiTableAccount,
      mdiTimelineClock
    }
  }),

  computed: {},

  methods: {
    logout() {
      return api.logout().then(() => {
        this.$auth.user = null;
        this.$auth.is = false;
        this.$router.push({ name: "Login" });
      });
    }
  }
};
</script>

<style>
#app__navigation-drawer {
  background: linear-gradient(
    90deg,
    var(--v-primary-base) 0px,
    var(--v-primary-base) 56px,
    var(--v-primary-darken1) 450px
  );
  z-index: 1001 !important;
}

#app__navigation-drawer_list {
  padding-bottom: 3.5rem !important;
  position: relative;
}
</style>

<style scoped>
.v-list-item__title {
  font-size: 20px !important;
}

.v-list .v-list-item--active,
.v-list .v-list-item--active .v-icon {
  color: white !important;
}
</style>
