<template>
  <v-app>
    <template v-if="$auth.is">
      <app-bar v-if="$vuetify.breakpoint.smAndDown" v-model="$root.drawer" />
      <app-drawer />
    </template>
    <v-main>
      <v-container
        class="app--content"
        fluid
        :style="{ padding: $vuetify.breakpoint.smAndDown ? '48px 0 0 0' : '0' }"
      >
        <v-fade-transition mode="out-in">
          <router-view />
        </v-fade-transition>
      </v-container>
    </v-main>
    <plugin-toast />
  </v-app>
</template>

<script>
import Bar from "@/components/Bar";
import Drawer from "@/components/Drawer";

export default {
  name: "App",

  components: {
    "app-bar": Bar,
    "app-drawer": Drawer
  },

  mounted() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    const vh = (window.innerHeight - (this.$vuetify.breakpoint.smAndDown ? 48 : 0)) * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    // We listen to the resize event
    window.addEventListener(
      "resize",
      this.debounce(() => {
        // We execute the same script as before
        let vh = (window.innerHeight - (this.$vuetify.breakpoint.smAndDown ? 48 : 0)) * 0.01;
        document.documentElement.style.setProperty("--vh", `${vh}px`);
      }, 400)
    );
  },

  methods: {
    debounce(func, threshold, execAsap) {
      var timeout;

      return function debounced() {
        var obj = this,
          args = arguments;
        function delayed() {
          if (!execAsap) func.apply(obj, args);
          timeout = null;
        }

        if (timeout) clearTimeout(timeout);
        else if (execAsap) func.apply(obj, args);

        timeout = setTimeout(delayed, threshold || 100);
      };
    }
  }
};
</script>

<style lang="scss">
html {
  --scrollbarBG: #ebebeb;
  overflow-y: auto;
}

* {
  scrollbar-width: thin;
  scrollbar-color: var(--v-primary-base) var(--scrollbarBG);
}

*::-webkit-scrollbar {
  width: 11px;
}

*::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}

*::-webkit-scrollbar-thumb {
  background-color: var(--v-primary-base);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}

.v-color-picker .v-color-picker__swatch:last-child {
  pointer-events: none;
  opacity: 0;
}

.v-application--wrap {
  background-size: cover;
  background-image: var(--application-background, none);
  background-position: center center;
}

.v-application {
  &.theme--light {
    .v-tooltip__content-opaque {
      background-color: #777777 !important;

      &.menuable__content__active {
        opacity: 1 !important;
      }
    }
  }
  &.theme--dark {
    .v-tooltip__content-opaque {
      background-color: #333333 !important;

      &.menuable__content__active {
        opacity: 1 !important;
      }
    }
  }
}
</style>
