import axios from "axios";

axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

// function getBaseUrl() {
//   if (process.env.APP_URL) {
//     return process.env.APP_URL + '/';
//   }
//   return "/api";
// }

/** Default config for axios instance */
const config = {
  baseURL: "/api",
  withCredentials: true
};

/** Creating the instance for axios */
const http = axios.create(config);
const CancelToken = axios.CancelToken;

http.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    if (error.response.status == 401) {
      window.App.$auth.user = null;
      window.App.$auth.is = false;
      window.App.$router.push({ name: "Login" });
    }
    return Promise.reject(error);
  }
);

export { CancelToken, http };
