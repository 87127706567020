import { http } from "./http";

const get = (date = null) => {
  return http.get(`/information` + (date ? `/${date}` : ""));
};

const set = data => {
  return http.post(`/information`, data);
};

const stop = data => {
  return http.delete(`/information`, { params: data });
};

const update = data => {
  return http.patch(`/information`, data);
};

export default {
  get,
  set,
  stop,
  update
};
