import Vue from "vue";
import App from "./App.vue";

import "@/styles/fonts.scss";
import "@/styles/globals.scss";
import "leaflet/dist/leaflet.css";

import { http } from "./api/http";
import auth from "./plugins/auth";
import emitter from "./plugins/emitter";
import leaflet from "./plugins/leaflet";
import longpress from "./plugins/longpress";
import toast from "./plugins/toast";
import vuetify from "./plugins/vuetify";
import wakeLock from "./plugins/wakeLock";
import router from "./router";

Vue.config.productionTip = false;

Vue.use(emitter);
Vue.use(auth, { http });
Vue.use(toast);
Vue.use(leaflet);
Vue.use(longpress);
Vue.use(wakeLock);

const app = new Vue({
  data: () => ({
    drawer: false
  }),
  router,
  vuetify,
  render: h => h(App)
}).$mount("#app");

window.App = app;
