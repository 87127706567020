import { http } from "./http";

const attachFile = (messageId, data) => http.post(`/chat/${messageId}`, data);

const messages = () => http.get("/chat/");

const users = () => http.get("/chat/users");

const sendMessage = data => http.post("/chat/rooms/1", data);

export default {
  attachFile,
  messages,
  sendMessage,
  users
};
