<template>
  <v-app-bar color="primary" dark dense flat fixed style="max-height: 48px;">
    <img alt="M" :src="require('@/assets/logo_white.svg')" style="height: 80%;" />
    <v-spacer />
    <v-app-bar-nav-icon @click="$root.drawer = true" />
  </v-app-bar>
</template>

<script>
export default {
  name: "App_Components_Bar",
};
</script>
