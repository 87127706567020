<template>
  <v-container fluid>
    <v-row>
      <v-col :cols="12" style="max-width: 800px; margin: auto;">
        <v-data-table
          :headers="headers"
          :items="manuals"
          style="position: relative;"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              @click="download(item)"
              color="success"
              elevation="0"
              fab
              x-small
            >
              <v-icon>
                mdi-download
              </v-icon>
            </v-btn>
            <v-btn
              class="ml-1"
              @click="
                destroyDialog.item = item;
                destroyDialog.dialog = true;
              "
              color="error"
              elevation="0"
              fab
              v-if="$auth.user.is_admin"
              x-small
            >
              <v-icon>
                mdi-delete
              </v-icon>
            </v-btn>
          </template>

          <template v-slot:footer>
            <v-dialog v-model="create.dialog" max-width="550px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="success"
                  dark
                  elevation="0"
                  style="position: absolute; bottom: 10px; left: 10px;"
                  v-bind="attrs"
                  v-on="on"
                >
                  Neues Manual erstellen
                </v-btn>
              </template>

              <v-card>
                <v-toolbar color="primary" dark>
                  Manual erstellen
                  <v-spacer />
                  <v-btn
                    @click="create.dialog = false"
                    color="error"
                    dark
                    elevation="0"
                    fab
                    small
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text class="pt-5">
                  <v-form ref="create" @submit.prevent="store()">
                    <v-text-field
                      label="Name"
                      name="name"
                      required
                      v-model="create.name"
                    />
                    <v-file-input
                      label="Datei"
                      outlined
                      dense
                      name="file"
                      required
                      v-model="create.file"
                    />
                    <v-btn @click="store()" color="success">Speichern</v-btn>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-dialog>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-dialog v-model="destroyDialog.dialog" max-width="550px">
      <v-card>
        <v-toolbar color="primary" dark elevation="0">
          {{ destroyDialog.item.name }} löschen?
          <v-spacer />
          <v-btn
            @click="destroyDialog.dialog = false"
            color="error"
            elevation="0"
            fab
            small
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-actions>
          <v-container fluid>
            <v-row>
              <v-col style="text-align: center;">
                <v-btn @click="destroyDialog.dialog = false" color="success">
                  Abbruch
                </v-btn>
              </v-col>
              <v-col style="text-align: center;">
                <v-btn @click="destroy(destroyDialog.item)" color="error">
                  Löschen
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import manuals from "@/api/manuals";

export default {
  name: "Manuals",

  data: () => ({
    create: {
      dialog: false,
      name: null,
      file: null
    },
    destroyDialog: {
      dialog: false,
      item: {}
    },
    headers: [
      {
        text: "Name",
        value: "name"
      },
      {
        text: "Von",
        value: "user.name"
      },
      {
        text: "",
        value: "actions",
        width: 110
      }
    ],
    manuals: []
  }),

  beforeRouteEnter(to, from, next) {
    next(vm => {
      manuals
        .index()
        .then(
          response =>
            (vm.manuals = response.data.data.sort((a, b) =>
              a.name.localeCompare(b.name)
            ))
        );
    });
  },

  methods: {
    destroy(manual) {
      manuals.destroy(manual.id).then(() => {
        this.manuals = this.manuals.filter(m => m.id !== manual.id);
        this.destroyDialog.dialog = false;
      });
    },
    download(manual) {
      window.open(manual.url, "_blank");
    },
    store() {
      const data = new FormData(this.$refs.create.$el);
      manuals.store(data).then(response => {
        this.manuals = [...this.manuals, response.data.data].sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        this.$refs.create.reset();
        this.create.dialog = false;
      });
    }
  }
};
</script>
