<template>
  <div class="py-3">
    <v-row class="ma-0">
      <v-col class="flex-grow-0">
        <user-avatar size="50px" :user="comment.user" />
      </v-col>
      <v-col>
        <v-row>
          <v-col>
            {{ comment.user.name }} <span>{{ created }}</span>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            {{ comment.text }}
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import UserAvatar from "./UserAvatar.vue";

export default {
  name: "Component_Comment",

  components: { UserAvatar },

  props: {
    comment: {
      type: Object,
      default: () => ({})
    }
  },

  computed: {
    created() {
      return new Date(this.comment.created_at).toLocaleString();
    }
  }
};
</script>
