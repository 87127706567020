<template>
  <svg
    :width="(46.708 / 61.464) * parseFloat(size, 10) + 'em'"
    :height="size"
    version="1.1"
    viewBox="0 0 46.708 61.464"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:cc="http://creativecommons.org/ns#"
    xmlns:dc="http://purl.org/dc/elements/1.1/"
    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
    style="margin-bottom: -0.3em;"
  >
    <path
      :fill="color"
      d="M 2.4593084,50.148638 V 0.19375329 H 32.036492 L 40.478234,9.569396 28.021561,28.007607 H 14.50445 L 24.345778,13.020184 H 14.291663 v 37.164208 z"
    />
  </svg>
</template>

<script>
export default {
  name: "P",

  props: {
    color: {
      type: String,
      default: "#000000"
    },
    size: {
      type: String,
      default: "1em"
    }
  }
};
</script>
