<template>
  <div>
    <v-tabs-items v-model="index" v-if="trip.id" :touch="{}">
      <v-tab-item
        class="px-2"
        style="max-width: min(100vw, 550px); margin: auto; padding-bottom: 48px; padding-top: 20px;"
      >
        <h2 class="is-size-3" v-if="trip.user">
          <user-avatar
            size="50px"
            :user="trip.user"
            style="float: left; margin-right: 10px;"
          />
          {{ trip.user.name }}
        </h2>
        <br />
        <v-text-field
          class="trip__title-input"
          name="name"
          :readonly="!isMy"
          :solo="!isMy"
          v-model="trip.name"
        />
        <v-row>
          <v-col class="flex-grow-0">
            <v-date-picker-menu
              autocomplete="none"
              :disabled="!isMy"
              name="date_from"
              v-model="trip.date_from"
            />
          </v-col>
          <v-col class="flex-grow-0 mt-5">
            —
          </v-col>
          <v-col class="flex-grow-0">
            <v-date-picker-menu
              autocomplete="none"
              :disabled="!isMy"
              name="date_to"
              v-model="trip.date_to"
            />
          </v-col>
        </v-row>
        <hr style="margin-top: 0.5em;" />
        <v-textarea
          name="comment"
          :readonly="!isMy"
          rows="10"
          :solo="!isMy"
          style="font-size: 1.2em;"
          v-model="trip.comment"
        /><br />
        <v-file-input label="GPX Datei" ref="gpx" v-model="trip.gpx_file" />
        <v-btn @click="edit" color="success" v-if="isMy">
          Bearbeiten
        </v-btn>
      </v-tab-item>
      <v-tab-item
        eager
        style="height: calc(var(--vh) * 100 - 40px); padding: 10px;"
      >
        <l-map ref="map" style="z-index: 0" />
      </v-tab-item>
      <v-tab-item
        style="max-width: min(100vw, 550px); margin: auto; height: calc(var(--vh) * 100 - 40px);"
      >
        <v-container
          class="px-0 pt-0"
          fluid
          style="position: relative; height: 100%;"
        >
          <v-row style="position: absolute; bottom: 0; left: 0; right: 0;">
            <v-col>
              <v-text-field
                color="primary"
                @keypress.enter="commentAdd"
                placeholder="Kommentar abgeben"
                v-model="comment"
              ></v-text-field>
            </v-col>
            <v-col class="flex-grow-0">
              <v-btn icon class="ml-4" color="primary" @click="commentAdd">
                <v-icon>mdi-send</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <div class="trip__comments-container">
            <div>
              <comment
                :comment="comment"
                :key="comment.id"
                v-for="comment in trip.comments"
              />
            </div>
          </div>
        </v-container>
      </v-tab-item>
      <v-tab-item style="height: calc(var(--vh) * 100 - 40px); padding: 10px;">
        <vue-picture-swipe :items="trip.media" :options="{ shareEl: false }" />
        <v-file-input
          hide-input
          multiple
          prepend-icon="mdi-file-upload"
          v-show="false"
        ></v-file-input>
        <v-btn
          absolute
          bottom
          color="primary"
          elevation="0"
          fab
          right
          small
          style="transform: translateY(-80%);"
        >
          <v-icon>mdi-file-upload</v-icon>
        </v-btn>
      </v-tab-item>
    </v-tabs-items>
    <v-bottom-navigation
      background-color="primary"
      dark
      fixed
      grow
      horizontal
      @change="changeIndex($event)"
      :height="40"
      :value="index"
    >
      <v-btn :value="0">
        <v-icon>mdi-information</v-icon>
      </v-btn>

      <v-btn :value="1">
        <v-icon>mdi-map</v-icon>
      </v-btn>

      <v-btn :value="2">
        <v-icon>mdi-chat</v-icon>
      </v-btn>

      <v-btn :value="3">
        <v-icon>mdi-image-multiple</v-icon>
      </v-btn>
    </v-bottom-navigation>
  </div>
</template>

<script>
import Flow from "@flowjs/flow.js";
import comments from "@/api/comments";
import api from "@/api/trips";
import Comment from "@/components/Comment.vue";
import LMap from "@/components/Map";
import UserAvatar from "@/components/UserAvatar.vue";
import VDatePickerMenu from "@/components/vuetify/VDatePickerMenu.vue";
import VuePictureSwipe from "@/components/VuePictureSwipe";

export default {
  name: "Trip",

  components: {
    Comment,
    LMap,
    UserAvatar,
    VDatePickerMenu,
    VuePictureSwipe
  },

  data: () => ({
    comment: "",
    commentsLoadable: true,
    flow: null,
    index: 0,
    trip: {}
  }),

  computed: {
    isMy() {
      return this.trip.user && this.trip.user.id == this.$auth.user.id;
    }
  },

  created() {
    this.flow = new Flow();
    api.show(this.$route.params.id).then(response => {
      this.trip = {
        ...response.data.data,
        startDate: new Date(response.data.data.date_from),
        endDate: new Date(response.data.data.date_to),
        color: response.data.data.user.color || "#888888"
      };
      this.$nextTick(() => {
        if (this.trip.gpx) {
          this.$refs.map.setPolyline(this.trip.gpx, {
            color: `${this.trip.color}${this.trip.entries.length > 0 ? "88" : ""}`
          });
        }
        if (this.trip.entries.length > 0)
          this.$refs.map.setPolyline(
            this.trip.entries.map(e => [e.lat, e.lng]),
            {
              color: this.trip.color
            }
          );
      });
    });
  },

  methods: {
    changeIndex(idx) {
      if (idx === 1) setTimeout(() => this.$refs.map.invalidateSize(), 200);
      this.index = idx;
    },
    commentAdd() {
      comments
        .store("trip", this.trip.id, { text: this.comment })
        .then(response => {
          this.trip.comments = [...this.trip.comments, response.data.data];
          this.comment = "";
          this.$toast("Kommentar gespeichert!");
        });
    },
    edit() {
      const formData = new FormData();
      ["id", "name", "date_from", "date_to", "comment"].map(key =>
        formData.append(key, this.trip[key])
      );
      formData.append("gpx_file", this.$refs.gpx.value);
      api
        .edit(formData)
        .then(response => {
          this.trip = {
            ...this.trip,
            ...response.data.data
          };
          this.$refs.map.setPolyline(this.trip.gpx);
          this.$toast("Ausflug bearbeited!");
        })
        .catch(() => {
          this.$toast.error("Fehler beim bearbeiten!");
        });
    },
    onFileSelect(event) {
      this.flow.addFiles(event);
      this.flow.upload();
    }
  }
};
</script>

<style lang="scss">
.trip__title-input {
  height: 2.5em !important;
  font-size: 2em !important;

  .v-input__slot {
    box-shadow: none !important;
    input {
      line-height: 2em !important;
      max-height: 1.5em !important;
    }
  }
}

.trip__comments-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 78px;
  flex: 1;
  display: flex;
  overflow: auto;

  > div {
    min-height: min-content;
    display: flex;
    flex-direction: column;
  }
}

.lb {
  height: 100%;
  .lb-grid {
    height: 100%;
  }
}
</style>
